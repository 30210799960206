import React from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo";

const divParaStyle = {
  marginTop: '70px'
}

const Impressum = () => {
  return (
      <Layout>
        <Seo title="Impressum" />
        <div className="content">
          <h1>Impressum</h1>
            <div style={divParaStyle}>
              <p>Naturspielgruppe Wakiti</p>
            </div>
            <div style={divParaStyle}>
              <h4>Kontaktadresse</h4>
              <p>Jill Zimmermann <br/>Reussinsel 44 <br/>6003 Luzern</p>
              <p>Telefon: <a href='tel:0041794724244'>079 472 42 44</a><br/>
              <a id='email' href='mailto:info@spielgruppe-wakiti.ch'>info@spielgruppe-wakiti.ch</a></p>
            </div>
        </div>
      </Layout>
  )
}

export default Impressum;